import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "react-intl"

import PrizeListRow from "./prizeListRow"

const Styles = styled.div`
  margin: 10rem 0;
  h4 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
`

export default function prizeList({ prizeListRef }) {
  const data = useStaticQuery(graphql`
    {
      allContentfulPlaygroundPrizeList(sort: { fields: order }) {
        nodes {
          title
          prize {
            description
            photo {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Styles ref={prizeListRef}>
      <h4>
        <FormattedMessage id="playground.prizes.prizeList" />
      </h4>
      {data.allContentfulPlaygroundPrizeList.nodes.map((place) => (
        <PrizeListRow
          key={place.order}
          title={place.title}
          place={place.order}
          prizes={place.prize}
        />
      ))}
    </Styles>
  )
}
