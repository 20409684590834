import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { FormattedMessage } from "react-intl"

const Styles = styled.div`
  .place {
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    @media (max-width: 375px) {
      font-size: 36px;
    }
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    color: "transparent";
    .trail {
      font-size: 50%;
    }
  }
  .glow {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ec006a 0%,
      rgba(236, 0, 106, 0) 100%
    );
    opacity: 0.7;
    position: relative;
    z-index: -1;
  }
  .glow:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .prizewrapper {
    text-align: center;
    height: 100%;
    position: relative;
    z-index: 1;
    margin: -90% 0 10% 0;
    .imgwrapper,
    p {
      max-width: 55%;
      margin: auto;
    }
    p {
      font-weight: bold;
      margin-top: 0.5rem;
    }
  }
`

export default function grandPrize(props) {
  const textGradient = (place) => {
    switch (place) {
      case 1:
        return {
          backgroundImage:
            "linear-gradient(135deg, #F7B321 0%, #F12711 100%, #F12711 100%)",
        }
      case 2:
        return {
          backgroundImage: "linear-gradient(135deg, #EC006A 0%, #F66C3D 100%)",
        }
      case 3:
        return {
          backgroundImage: "linear-gradient(135deg, #E6195B 0%, #992F8B 100%)",
        }
      default:
        break
    }
  }
  return (
    <Styles>
      <div className="place" style={textGradient(props.place)}>
        <FormattedMessage
          id={`playground.prizes.place.${props.place}`}
          values={{
            span: (chunks) => <span className="trail">{chunks}</span>,
          }}
        />
      </div>
      <div className="glow" />
      <div className="prizewrapper">
        <div className="imgwrapper">
          <Img fluid={props.img} alt="prize photo" />
        </div>
        <p>{props.desc}</p>
      </div>
    </Styles>
  )
}
