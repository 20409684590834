import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

const Styles = styled.div`
  margin-top: 10rem;
  h4 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;

    img {
      width: calc(25% - 1rem);
      padding: 0.5rem;
    }
  }

  @media screen and (max-width: 992px) {
    .card-list img {
      width: calc(50% - 1rem);
    }
  }

  @media screen and (max-width: 450px) {
    .card-list img {
      width: calc(100% - 2rem);
      padding: 1rem;
    }
  }
`

const getContents = () => {
  const result = []
  for (let i = 0; i < 4; ++i) {
    result.push(
      <img
        src={`/content/img/how_to_claim_${i}.svg`}
        alt={`How to claim ${i}`}
      />
    )
  }
  return result
}

export default function howToClaim() {
  return (
    <Styles>
      <h4>
        <FormattedMessage id="playground.prizes.howToClaim.title" />
      </h4>
      <div className="card-list">{getContents()}</div>
    </Styles>
  )
}
