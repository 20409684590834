import React from "react"
import styled from "styled-components"
import useGetWindowWidth from "../../../utils/useGetWindowWidth"

import Carousel from "../../Carousel"
import Prize from "./prize"

const Styles = styled.div`
  .card {
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 0;
    margin: 1.5rem 0;
    .card-header {
      padding: 1.5rem;
      text-align: center;
      color: #f3f3f3;
      border-radius: 10px 10px 0 0;
      font-weight: bold;
      font-size: 24px;
    }
    .card-body {
      padding: 2rem;
      color: #131313;
      .info {
        font-family: DM Sans;
        font-weight: bold;
        text-align: center;
        padding-bottom: 1rem;
      }
      .carousel-slide {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
      }
      .no-carousel {
        position: relative;
        left: 0;
        display: flex;
        align-items: flex-start;
        overflow-x: auto;
        div {
          width: 100px;
          margin-right: 1rem;
          margin-left: 0.5rem;
        }
      }
      @media (max-width: 992px) {
        padding: 1.5rem;
      }
      @media (max-width: 576px) {
        padding-right: 0;
      }
    }

    @media (max-width: 768px) {
      .card-body {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
`

const cardHeaderBg = (place) => {
  switch (place) {
    case 1:
      return "linear-gradient(135deg, #F7B321 0%, #F12711 100%, #F12711 100%)"
    case 2:
      return "linear-gradient(135deg, #F06A3C 0%, #EC006A 100%)"
    case 3:
      return "linear-gradient(135deg, #E6195B 0%, #992F8B 100%)"
    default:
      return "linear-gradient(90deg, #CB0000 0%, #F2195F 100%)"
  }
}

export default function prizeListRow({ title, place, prizes }) {
  const prizeSlicer = (arr) => {
    var sliced = []
    if (arr.length % 4 === 0) {
      for (let i = 0; i < Math.floor(arr.length / 4); i++) {
        const slc = arr.slice(i * 4, i * 4 + 4)
        sliced.push(slc)
      }
    } else {
      for (let i = 0; i < Math.floor(arr.length / 4) + 1; i++) {
        const slc = arr.slice(i * 4, i * 4 + 4)
        sliced.push(slc)
      }
    }
    return sliced
  }
  return (
    <Styles>
      <div className="card">
        <div
          className="card-header"
          style={{ background: cardHeaderBg(place) }}
        >
          {title}
        </div>
        <div className="card-body">
          <div className="info">
            Participant(s) with this rank shall receive ALL the prizes below
          </div>
          {useGetWindowWidth() > 576 ? (
            prizeSlicer(prizes).length > 1 ? (
              <Carousel>
                {prizeSlicer(prizes).map((slide, idx) => (
                  <div key={idx} className="carousel-slide">
                    {slide.map((item, idx) => (
                      <Prize
                        key={idx}
                        img={item.photo.fluid}
                        desc={item.description}
                        className="prizeItem"
                      />
                    ))}
                  </div>
                ))}
              </Carousel>
            ) : (
              <div className="carousel-slide">
                {prizes.map((item, idx) => (
                  <Prize
                    key={idx}
                    img={item.photo.fluid}
                    desc={item.description}
                    className="prizeItem"
                  />
                ))}
              </div>
            )
          ) : (
            <div className="no-carousel">
              {prizes.map((item, idx) => (
                <Prize
                  key={idx}
                  img={item.photo.fluid}
                  desc={item.description}
                  className="prizeItem"
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </Styles>
  )
}
