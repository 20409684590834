import React, { useRef } from "react"
import styled from "styled-components"

import GrandPrizes from "./grandPrizes"
import HowToClaim from "./howToClaim"
import PrizeList from "./prizeList"

const Styles = styled.div`
  width: 100%;
  overflow: hidden;
`

export default function index() {
  const prizeListRef = useRef(null)
  return (
    <Styles>
      <GrandPrizes prizeListRef={prizeListRef} />
      <HowToClaim />
      <PrizeList prizeListRef={prizeListRef} />
    </Styles>
  )
}
