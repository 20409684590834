import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "react-intl"

import chevron from "../../../../assets/images/playground-assets/see-prize.svg"

import GrandPrizeComponent from "./grandPrizeComponent"

const Styles = styled.div`
  h4 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
  .grandPrizes {
    display: flex;
    justify-content: space-between;
    .seePrizes {
      position: relative;
      z-index: 2;
      cursor: pointer;
      height: 5rem;
      margin-top: 2rem;
      text-align: center;
      font-weight: bold;
      background-image: linear-gradient(135deg, #ff8849 0%, #ffcb15 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      color: "transparent";
      img {
        display: block;
        margin: auto;
        margin-top: 1rem;
        cursor: pointer;
      }
    }
    .seePrizes.mob {
      display: none;
      @media (max-width: 576px) {
        display: block;
      }
    }
    .seePrizes.full {
      display: none;
      @media (min-width: 577px) {
        display: block;
      }
    }
    .first {
      flex: 1 1;
      order: 2;
      margin: 0 2rem;
      display: flex;
      flex-direction: column;
    }
    .second {
      flex: 1 1;
      order: 1;
      align-self: flex-end;
    }
    .third {
      flex: 1 1;
      order: 3;
      align-self: flex-end;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      .first {
        order: 1;
      }
      .second {
        order: 2;
        align-self: auto;
      }
      .third {
        order: 3;
        align-self: auto;
      }
      .seePrizes {
        order: 4;
      }
    }
  }
`
const grandPrizes = (props) => {
  const data = useStaticQuery(graphql`
    query GrandPrizeHadiah {
      allContentfulPlaygroundSinglePrize(
        filter: { photo: { description: { eq: "grandprize" } } }
      ) {
        nodes {
          description
          photo {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)
  const scrollToPrizeList = () => {
    if (props.prizeListRef.current) {
      props.prizeListRef.current.scrollIntoView()
    }
  }
  const grandprize = data.allContentfulPlaygroundSinglePrize.nodes.sort(
    (a, b) => {
      return a.description < b.description
    }
  )
  const first = grandprize[0]
  const second = grandprize[1]
  const third = grandprize[2]

  return (
    <Styles>
      <h4>
        <FormattedMessage id="playground.prizes.description" />
      </h4>
      <div className="grandPrizes">
        <div className="first">
          <GrandPrizeComponent
            place={1}
            img={first.photo.fluid}
            desc={first.description}
          />
          <div className="seePrizes full" onClick={() => scrollToPrizeList()}>
            <FormattedMessage id="playground.prizes.seePrizeList" />
            <img
              src={chevron}
              alt="see prizes"
              onClick={() => scrollToPrizeList()}
            />
          </div>
        </div>
        <div className="second">
          <GrandPrizeComponent
            place={2}
            img={second.photo.fluid}
            desc={second.description}
          />
        </div>
        <div className="third">
          <GrandPrizeComponent
            place={3}
            img={third.photo.fluid}
            desc={third.description}
          />
        </div>
        <div className="seePrizes mob" onClick={() => scrollToPrizeList()}>
          <FormattedMessage id="playground.prizes.seePrizeList" />
          <img
            src={chevron}
            alt="see prizes"
            onClick={() => scrollToPrizeList()}
          />
        </div>
      </div>
    </Styles>
  )
}

export default grandPrizes
