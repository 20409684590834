import React from "react"
import styled from "styled-components"
import useGetWindowWidth from "../../../utils/useGetWindowWidth"

import Img from "gatsby-image"

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  width: 200px;

  .prize-desc {
    margin-top: 0.5em;
    font-family: "DM Sans";
  }

  @media (max-width: 1200px) {
    width: 150px;
  }
  @media (max-width: 992px) {
    width: 120px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 577px) {
    .photo {
      width: 100%;
      padding: 0;
    }
  }
`

export default function prize({ img, desc }) {
  return (
    <Styles>
      {useGetWindowWidth() > 576 ? (
        <div className="photo">
          <Img fluid={img} alt="prize photo" />
        </div>
      ) : (
        <Img fluid={img} alt="prize photo" />
      )}
      <div className="prize-desc">{desc}</div>
    </Styles>
  )
}
